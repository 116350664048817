import { computed, type ComputedRef } from 'vue'
import { FieldCategoryEnum, TicketAasmStateEnum } from '@/gql/requests.generated'
import { DashboardTicket } from '@/gql/types'

export const ticketStates = (ticket: ComputedRef<DashboardTicket>, currentUserId: string) => {
  const isCrewTicket = computed(() => ticket.value?.primaryFieldCategory?.key === FieldCategoryEnum.Crew)
  const isCompleted = computed(() => ticket.value?.aasmState === TicketAasmStateEnum.Completed)
  const showEditButton = computed(() => ticket.value?.isEditable && !isCrewTicket.value)
  const canEditTicket = computed(
    () =>
      ticket.value?.isEditable &&
      !isCrewTicket.value &&
      (isOwnTicket.value || hasNoOwnershipAndUnlocked.value || personalizedForSomeoneElseWithoutAUserAccount.value),
  )
  const isOwnTicket = computed(() => ticket.value?.userId === currentUserId)
  const hasNoOwnershipAndUnlocked = computed(
    () => ticket.value?.ownership == null && ticket.value?.aasmState === TicketAasmStateEnum.Unlocked,
  )
  const personalizedForSomeoneElseWithoutAUserAccount = computed(() => ticket.value?.userId == null)

  return {
    canEditTicket,
    isCompleted,
    isCrewTicket,
    hasNoOwnershipAndUnlocked,
    isOwnTicket,
    showEditButton,
  }
}
