<template>
  <button v-if="canEditTicket" class="btn-text-purple-m" @click="modal.open()">
    <MdiSvg :path="mdiPencilOutline" size="20" />
    {{ isCompleted ? t('ticket.edit') : t('ticket.add_details') }}
  </button>
  <TooltipWrapper v-else top>
    <template #trigger>
      <span class="btn-text-purple-m text-grey-500">
        <MdiSvg :path="mdiPencilOutline" size="20" />
        {{ isCompleted ? t('ticket.edit') : t('ticket.add_details') }}
      </span>
    </template>
    <template #content>
      <div>{{ t('ticket.not_own_ticket', { name: ownerDisplayName }) }}</div>
    </template>
  </TooltipWrapper>

  <ModalDialog ref="modal" class="light">
    <template #header>
      <div class="bg-black text-white">
        <div class="flex justify-between px-4 py-4">
          <div class="flex flex-col gap-4">
            <span class="text-label-m-bold">Ticket #{{ props.number }}</span>
            <h4 class="text-headline-m-uppercase">{{ props.ticket.ticketCategory.event.shortName }}</h4>
          </div>
          <MdiSvg class="cursor-pointer" :path="mdiClose" size="24" @click.stop.prevent="modal.close()" />
        </div>
      </div>
    </template>

    <div class="mx-4 max-w-[640px] pb-20 laptop:pb-0">
      <div class="text-headline-s mt-6">{{ t('ticket.form.headline') }}</div>
      <p class="mt-4">{{ t('ticket.form.disclaimer') }}</p>

      <TicketForm class="mt-8" :ticket="props.ticket" :user-properties="props.userProperties" @close-form="modal.close()" />
    </div>
  </ModalDialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { mdiClose, mdiPencilOutline } from '@mdi/js'
import { MdiSvg, ModalDialog, TooltipWrapper } from '@ramp106/omrjs-core-ui'
import { DashboardTicket, UserPropertiesPreferences } from '@/gql/types'
import { ticketStates } from './ticketStateHelpers'
import TicketForm from './TicketForm.vue'
import { useI18n } from 'vue-i18n'

const modal = ref<InstanceType<typeof ModalDialog>>()

const { t } = useI18n()

const props = defineProps<{
  currentUserId?: string
  number: number
  ticket: DashboardTicket
  userProperties: UserPropertiesPreferences
}>()

const ticketRef = computed<DashboardTicket>(() => props.ticket)
const { canEditTicket, isCompleted } = ticketStates(ticketRef, props.currentUserId)
const ownerDisplayName = computed(() => {
  if (props.ticket.firstName && props.ticket.lastName) {
    return `${props.ticket.firstName} ${props.ticket.lastName}`
  } else if (props.ticket.email) {
    return props.ticket.email
  } else {
    return t('ticket.unknown_user')
  }
})
</script>
