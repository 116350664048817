<template>
  <div class="overflow-clip rounded-lg border border-grey-300 bg-white">
    <!-- HEADER -->
    <div class="bg-black px-8 py-4 text-white">
      <h5 class="text-label-m-bold text-grey-600">Ticket #{{ number }}</h5>

      <div class="flex items-center gap-2">
        <span class="text-headline-s-uppercase">{{ ticket.ticketCategory.event.shortName }}</span>
        <span v-if="ticketName" class="text-body-l uppercase">
          {{ ticketName }}
        </span>
      </div>
    </div>

    <!-- BODY -->
    <div class="grid gap-x-4 gap-y-6 p-8 laptop:grid-cols-2">
      <div class="col-start-1">
        <div class="text-headline-xs-uppercase inline-flex items-center gap-2">
          <MdiSvg v-if="isCompleted" class="text-green" :path="mdiCheckboxMarkedCircleOutline" size="24" />
          <MdiSvg v-else class="text-red-900" :path="mdiClose" size="24" />
          {{ t('ticket.ticket_details') }}
        </div>

        <div class="ml-8 mt-3">
          <div v-if="isCompleted" class="text-body-s">
            <p>{{ ticket.firstName }} {{ ticket.lastName }}</p>
            <p>{{ translatedJobLevel }}</p>
            <p>{{ ticket.email }}</p>
          </div>

          <p v-else-if="canEditTicket" class="text-grey-600">{{ t('ticket.not_completed_ticket_cta') }}</p>
        </div>
      </div>

      <div class="ml-8 flex flex-col gap-3 laptop:col-start-1 laptop:row-start-2">
        <EditTicket
          v-if="showEditButton"
          :current-user-id="props.currentUserId"
          :number="props.number"
          :ticket="props.ticket"
          :user-properties="props.userProperties"
        />
        <a
          v-if="isCompleted && ticket.pdfDownloadUrl && isCurrentTicket"
          class="btn-text-black-m"
          :href="`${ticket.pdfDownloadUrl}?locale=${locale}`"
          @click="($event.target as HTMLElement).blur()"
        >
          <MdiSvg :path="mdiTrayArrowDown" size="20" />
          {{ t('ticket.download_ticket') }}
        </a>

        <a
          v-if="!isCrewTicket && isCompleted && ticket.passbookDownloadUrl"
          class="btn-text-black-m"
          :href="ticket.passbookDownloadUrl"
          @click="($event.target as HTMLElement).blur()"
        >
          <MdiSvg :path="mdiTrayArrowDown" size="20" />
          {{ t('ticket.download_pkpass') }}
        </a>
      </div>

      <template v-if="ticket.isPaymentInfoEnabled">
        <div class="row-start-4 laptop:col-start-2 laptop:row-start-1">
          <div>
            <div class="text-headline-xs-uppercase inline-flex items-center gap-2">
              <MdiSvg v-if="ticket.paymentInfo" class="text-green" :path="mdiCheckboxMarkedCircleOutline" size="24" />
              <MdiSvg v-else class="text-red-900" :path="mdiClose" size="24" />
              {{ t('ticket.payment_info') }}
            </div>

            <div class="ml-8 mt-3">
              <div v-if="ticket.paymentInfo">
                <p>{{ ticket.paymentInfo.company }}</p>
                <p>{{ ticket.paymentInfo.address }}</p>
                <p>{{ ticket.paymentInfo.additionalAddress }}</p>
                <p>{{ ticket.paymentInfo.zip }} {{ ticket.paymentInfo.city }}</p>
                <p>{{ translatedPaymentInfoCountryName }}</p>
                <p>{{ ticket.paymentInfo.vatNumber }}</p>
                <p>{{ ticket.paymentInfo.customerReference }}</p>
              </div>

              <div v-else>{{ t('payment_info.no_payment_info_cta') }}</div>
            </div>
          </div>
        </div>

        <div v-if="!ticket.isTicketCategoryEnded" class="row-start-5 ml-8 laptop:col-start-2 laptop:row-start-2">
          <EditPaymentInfo :number="number" :ticket="ticket" :user-properties="userProperties" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { mdiClose, mdiCheckboxMarkedCircleOutline, mdiTrayArrowDown } from '@mdi/js'
import { MdiSvg } from '@ramp106/omrjs-core-ui'
import { FieldCategoryEnum } from '@/gql/requests.generated'
import { DashboardTicket, UserPropertiesPreferences } from '@/gql/types'
import EditTicket from './EditTicket.vue'
import EditPaymentInfo from './EditPaymentInfo.vue'
import assertUnreachable from '@/helpers/assertUnreachable'
import { ticketStates } from './ticketStateHelpers'

interface Props {
  currentEventSlug?: string
  currentUserId?: string
  number: number
  ticket: DashboardTicket
  userProperties: UserPropertiesPreferences
}

const props = defineProps<Props>()

const { t, locale } = useI18n()

const translatedJobLevel = computed(() => {
  return props.userProperties.jobLevels.find((level) => level.enum === props.ticket.jobLevel)?.name
})

const isCurrentTicket = computed(() => {
  const festivalEndsAt = new Date(props.ticket.ticketCategory.event.endsAt)
  const targetDatePlusOneDay = new Date(festivalEndsAt.getTime() + 24 * 60 * 60 * 1000)
  const currentDate = new Date()
  return targetDatePlusOneDay > currentDate
})

const ticketRef = computed<DashboardTicket>(() => props.ticket)
const { canEditTicket, isCompleted, isCrewTicket, showEditButton } = ticketStates(ticketRef, props.currentUserId)

const ticketName = computed(() => {
  if (!props.ticket.primaryFieldCategory) return null

  // This code is written with a switch statement so that `assertUnreachable` would break type checking when new unspported keys are added
  switch (props.ticket.primaryFieldCategory.key) {
    case FieldCategoryEnum.Construction:
      return t('ticket.fieldCategory.construction')
    case FieldCategoryEnum.Crew:
      return t('ticket.fieldCategory.crew')
    case FieldCategoryEnum.Exhibitor:
      return t('ticket.fieldCategory.exhibitor')
    case FieldCategoryEnum.Guest:
      return t('ticket.fieldCategory.guest')
    case FieldCategoryEnum.GuestFfwd:
      return t('ticket.fieldCategory.guest_ffwd')
    case FieldCategoryEnum.Press:
      return t('ticket.fieldCategory.press')
    case FieldCategoryEnum.PressFfwd:
      return t('ticket.fieldCategory.press_ffwd')
    case FieldCategoryEnum.Speaker:
      return t('ticket.fieldCategory.speaker')
    case FieldCategoryEnum.Vip:
      return t('ticket.fieldCategory.vip')
    default:
      assertUnreachable(
        props.ticket.primaryFieldCategory.key,
        `Unsupported primaryFieldCategory.key value: ${props.ticket.primaryFieldCategory.key}`,
      )
      return null
  }
})

const translatedPaymentInfoCountryName = computed(
  () => props.userProperties.countries.find((item) => item.code === props.ticket.paymentInfo.country)?.translation,
)
</script>
