<template>
  <div class="w-full">
    <div class="responsive-container mx-auto mt-14 max-w-96 tablet:mt-40">
      <h1>{{ $t('complete_profile.headline') }}</h1>

      <p v-if="isReviews" class="mt-2">{{ $t('complete_profile.sub_headline_reviews') }}</p>

      <form class="mt-4" @submit.prevent="submitForm">
        <div v-for="component in profileScopeComponents" :key="component.scope">
          <component :is="component.component" />
        </div>
        <CheckBox v-if="updateTerms" size="medium" name="privacy_terms">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span class="terms_html" v-html="$t('complete_profile.privacy_terms_html')"></span>
        </CheckBox>

        <button type="submit" class="btn-primary-yellow-m w-full" :disabled="!meta.valid && submitCount !== 0">
          {{ isReviews ? $t('complete_profile.cta_reviews') : $t('complete_profile.cta') }}
        </button>
      </form>
    </div>

    <AppNotification />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { object, string, boolean } from 'yup'
import { CheckBox } from '@ramp106/omrjs-vee-validate'

import { ProfileScopeEnum, SalutationTypeEnum } from '@/gql/schema.generated'
import AppNotification from '@/components/AppNotification.vue'
import BasicScope from '@/components/CompleteProfile/BasicScope.vue'
import DetailedScope from '@/components/CompleteProfile/DetailedScope.vue'
import NetworkingTerms from '@/components/CompleteProfile/NetworkingTerms.vue'
import ReviewsTerms from '@/components/CompleteProfile/ReviewsTerms.vue'
import { authenticityToken, assertUnreachable } from '@/helpers'
import { useNotificationStore } from '@/stores/notification'

const props = defineProps<{
  url: string
  updateTerms: boolean
  isReviews: boolean
  requiredScopes: ProfileScopeEnum[]
  userData: {
    first_name: string | null
    last_name: string | null
    salutation: SalutationTypeEnum | null
    birthdate: string | null
    reviews_terms: boolean | null
    networking_terms: boolean | null
  }
}>()

const { t } = useI18n()
const { showError } = useNotificationStore()

const shape = {
  salutation: string().oneOf(Object.values(SalutationTypeEnum)),
  first_name: string(),
  last_name: string(),
  birth_day: string(),
  birth_month: string(),
  birth_year: string(),
  networking_terms: boolean(),
  reviews_terms: boolean(),
  privacy_terms: boolean().oneOf([true]),
}

if (props.requiredScopes.includes(ProfileScopeEnum.Detailed)) {
  shape.salutation = shape.salutation.required()
  shape.birth_day = shape.birth_day.required()
  shape.birth_month = shape.birth_month.required()
  shape.birth_year = shape.birth_year.required()
}

if (props.requiredScopes.includes(ProfileScopeEnum.Basic) || props.requiredScopes.includes(ProfileScopeEnum.Detailed)) {
  shape.first_name = shape.first_name.required()
  shape.last_name = shape.last_name.required()
}

if (props.requiredScopes.includes(ProfileScopeEnum.NetworkingTerms)) {
  shape.networking_terms = shape.networking_terms.required()
}

if (props.requiredScopes.includes(ProfileScopeEnum.ReviewsTerms)) {
  shape.reviews_terms = shape.reviews_terms.required()
}

if (props.updateTerms) {
  shape.privacy_terms = shape.privacy_terms.required()
}

const schema = object().shape(shape)

const { meta, values, submitCount, handleSubmit } = useForm({
  validationSchema: toTypedSchema(schema),
  initialValues: {
    first_name: props.userData.first_name ?? undefined,
    last_name: props.userData.last_name ?? undefined,
    salutation: props.userData.salutation ?? undefined,
    networking_terms: props.userData.networking_terms ?? formDefaultValueForScope(ProfileScopeEnum.NetworkingTerms),
    reviews_terms: props.userData.reviews_terms ?? formDefaultValueForScope(ProfileScopeEnum.ReviewsTerms),
    birth_year: props.userData.birthdate?.split('-')[0] ?? undefined,
    birth_month: props.userData.birthdate ? props.userData.birthdate.split('-')[1] : undefined,
    birth_day: props.userData.birthdate?.split('-')[2] ?? undefined,
  },
})

const profileScopeComponents = computed(() =>
  props.requiredScopes.map((profileScope) => {
    switch (profileScope) {
      case ProfileScopeEnum.Basic:
        return { scope: profileScope, component: BasicScope }
      case ProfileScopeEnum.Detailed:
        return { scope: profileScope, component: DetailedScope }
      case ProfileScopeEnum.NetworkingTerms:
        return { scope: profileScope, component: NetworkingTerms }
      case ProfileScopeEnum.ReviewsTerms:
        return { scope: profileScope, component: ReviewsTerms }
      default:
        assertUnreachable(profileScope, "Didn't expect to get here")
    }
  }),
)

const submitForm = handleSubmit(() => {
  const birthdate = [values.birth_year, values.birth_month, values.birth_day].join('-')

  fetch(props.url, {
    method: 'PUT',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'X-CSRF-Token': authenticityToken,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      user: {
        ...values,
        ...(ProfileScopeEnum.Detailed ? { birthdate } : {}),
      },
    }),
  })
    .then((response) => response.json())
    .then((json) => {
      if (!json.success) {
        json.errors.forEach((error) => showError(error))
        return
      }

      window.location.href = json.redirect_to
    })
    .catch((error) => {
      console.error(error)
      showError(t('errors.generic'))
    })
})

function formDefaultValueForScope(scope: ProfileScopeEnum) {
  // If the scope is required, we want to set the value to false,
  // otherwise we want to set it to undefined and the value won't be sent to the server.
  return props.requiredScopes.includes(scope) ? false : undefined
}
</script>
